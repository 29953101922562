import * as React from "react";
import { graphql } from "gatsby";
import { Parallax } from "react-scroll-parallax";
import { ParallaxProvider } from "react-scroll-parallax";

import Img from "gatsby-image";

import Nav from "src/components/Global/Nav/Nav.js";
import Mainwrapper from "src/components/Mainwrapper.js";
import styled from "styled-components";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import Markdown from "markdown-to-jsx";
import noHangers from "src/assets/fn/noHangers.js";

import colors from "src/assets/styles/colors.js";
import TitleH1 from "src/components/Typography/TitleH1.js";

import Footer from "src/components/Footer/Footer.js";

import SEO from "src/components/Global/SEO.js";
import Cookies from "src/components/Global/Cookies.js";

const Wrapper = styled.div`
  width: 83.33%;
  margin-left: 8.33%;
  margin-right: 8.33%;
  padding-top: 100rem;

  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    padding-top: 150rem;
  }
`;

const Main = styled.div`
  background-color: ${colors.grey};
`;

const StyledTitle = styled(TitleH1)`
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 50rem;
  }
`;

const CardWrapper = styled.div`
  filter: drop-shadow(0rem 0rem 20rem rgba(0, 0, 0, 0.08));
  border-radius: 8rem;
  padding: 50rem;
  width: calc(100% - 100rem);
  background-color: ${colors.white};

  display: flex;
  justify-content: space-between;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-wrap: wrap;
    padding: 25rem;
    width: calc(100% - 50rem);
  }
`;

const CardLeeft = styled.div`
  width: 40%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-bottom: 50rem;
  }
`;

const SubTitle = styled.h2`
  font-size: ${typographySizes.sm}rem;
  max-width: 250rem;
  line-height: 1.2;
`;

const CardRight = styled.div`
  width: 60%;
  .markdown {
    font-size: ${typographySizes.s}rem;
    font-weight: 100;
    color: ${colors.dark};
    line-height: 1.4;
    p {
      margin-bottom: 20rem;
    }
    strong {
      font-weight: bold;
      font-size: inherit;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 20rem;
      line-height: 1.1;
    }
    h1 {
      font-size: ${typographySizes.l}rem;
    }
    h2 {
      font-size: ${typographySizes.m}rem;
    }
    h3 {
      font-size: ${typographySizes.mM}rem;
    }
    h4 {
      font-size: ${typographySizes.sm}rem;
    }
    h5 {
      font-size: ${typographySizes.sm}rem;
    }
    h6 {
      font-size: ${typographySizes.sm}rem;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;
const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  justify-content: space-between;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
const ImgWrapper = styled.div`
  width: 50%;
  margin-right: 0%;
  position: relative;
  align-self: flex-end;
  height: 300rem;

  @media (max-width: ${mediaQuery.laptop}) {
    margin-right: 0%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    position: relative;
    height: inherit;
    width: 100%;
    height: inherit;
    display: none;
  }
  @media (max-width: ${mediaQuery.mobile}) {
    position: absolute;
    bottom: 0;

    height: 40vh;
  }

  @media (max-height: 700px) and (max-width: ${mediaQuery.mobile}) {
  }
`;
const FirstImg = styled.div`
  width: 66.66%;
  position: absolute;

  top: 5vw;
  @media (max-width: ${mediaQuery.desktop}) {
    top: 5vw;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    top: 5vw;
  }
  @media (max-width: ${mediaQuery.mobile}) {
  }
`;
const SecondtImg = styled.div`
  width: 66.66%;
  position: absolute;
  top: 0vw;

  left: 33.33%;
  top: 0vw;
  @media (max-width: ${mediaQuery.desktop}) {
    top: -5vw;
  }
  @media (max-width: ${mediaQuery.laptop}) {
    top: -2vw;
  }
  @media (max-width: ${mediaQuery.mobile}) {
    position: relative;
    top: -15vh;
  }
`;

const PrivacyPolicy = ({ data }) => {
  return (
    <Main>
      <SEO
        title={data.strapiHomePage.SEO.Title}
        description={data.strapiHomePage.SEO.Description}
        image={data.strapiHomePage.SEO.Img.localFile.publicURL}
        lang={data.strapiHomePage.locale}
      />
      <Cookies
        yes={data.strapiCookiesAndPrivacyPolicy.Cookies_button_yes}
        no={data.strapiCookiesAndPrivacyPolicy.Cookies_button_no}
        text={data.strapiCookiesAndPrivacyPolicy.Cookies_text.data.Cookies_text}
        pageName={data.strapiCookiesAndPrivacyPolicy.Privacy_policy_page_name}
        lang={data.strapiCookiesAndPrivacyPolicy.locale}
      />
      <Nav
        lang={data.strapiCookiesAndPrivacyPolicy.locale}
        logo={data.strapiNavigation.Logo.localFile.childImageSharp.fluid}
        variants={data.strapiNavigation.Variants}
        features={data.strapiNavigation.Features}
        inspirations={data.strapiNavigation.Inspirations}
        buyLink={data.strapiNavigation.Buy_link}
        buyName={data.strapiNavigation.Buy_name}
        labelCreatorLink={data.strapiNavigation.Label_creator_link}
        labelCreatorName={data.strapiNavigation.Label_creator_name}
        imgMobile={
          data.strapiNavigation.Mobile_img.localFile.childImageSharp.fluid
        }
        url={data.allStrapiCookiesAndPrivacyPolicy.edges}
      />
      <Mainwrapper>
        {" "}
        <Wrapper>
          <ParallaxProvider>
            <StyledWrapper>
              <StyledTitle>
                {data.strapiCookiesAndPrivacyPolicy.Privacy_policy_page_name}
              </StyledTitle>
              <ImgWrapper
                data-sal="slide-up"
                data-sal-delay="400"
                data-sal-easing="ease"
                data-sal-duration="500"
              >
                <FirstImg>
                  <Parallax speed={20}>
                    <Img
                      loading="eager"
                      fluid={
                        data.strapiCookiesAndPrivacyPolicy.Header_first_binder
                          .localFile.childImageSharp.fluid
                      }
                    />
                  </Parallax>
                </FirstImg>
                <SecondtImg>
                  <Parallax speed={40}>
                    <Img
                      loading="eager"
                      fluid={
                        data.strapiCookiesAndPrivacyPolicy.Header_second_binder
                          .localFile.childImageSharp.fluid
                      }
                    />
                  </Parallax>
                </SecondtImg>
              </ImgWrapper>
            </StyledWrapper>
          </ParallaxProvider>
          <CardWrapper>
            <CardLeeft>
              <SubTitle>Polityka Przetwarzania Danych Osobowych</SubTitle>
            </CardLeeft>

            <CardRight>
              <Markdown className={"markdown"}>
                {noHangers(
                  data.strapiCookiesAndPrivacyPolicy.Privacy_policy_content.data
                    .Privacy_policy_content
                )}
              </Markdown>
            </CardRight>
          </CardWrapper>
        </Wrapper>
      </Mainwrapper>

      <Footer
        logo={data.strapiFooter.Logo}
        img={data.strapiFooter.Img}
        links={data.strapiFooter.Link}
        lang={data.strapiCookiesAndPrivacyPolicy.locale}
        privacyPolicy={
          data.strapiCookiesAndPrivacyPolicy.Privacy_policy_page_name
        }
      />
    </Main>
  );
};

export default PrivacyPolicy;

export const IndexQuery = graphql`
  query PrivacyPolicy($locale: String!) {
    allStrapiCookiesAndPrivacyPolicy {
      edges {
        node {
          locale
          Privacy_policy_page_name
        }
      }
    }
    strapiCookiesAndPrivacyPolicy(locale: { eq: $locale }) {
      Header_first_binder {
        localFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      Header_second_binder {
        localFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      locale
      Privacy_policy_content {
        data {
          Privacy_policy_content
        }
      }
      Privacy_policy_Subtitle
      Cookies_button_no
      Cookies_button_yes
      Cookies_text {
        data {
          Cookies_text
        }
      }
      Privacy_policy_page_name
    }

    strapiHomePage(locale: { eq: $locale }) {
      SEO {
        Description
        Title
        Img {
          localFile {
            publicURL
          }
        }
      }
    }

    strapiNavigation(locale: { eq: $locale }) {
      Buy_link
      Features
      Buy_name
      Inspirations
      Label_creator_link
      Label_creator_name
      Variants
      Mobile_img {
        localFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      Logo {
        localFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
    strapiFooter(locale: { eq: $locale }) {
      Logo {
        localFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      Img {
        localFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      Link {
        Link
        Name
      }
    }
  }
`;
